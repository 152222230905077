import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Experience from '../components/experience';
import SEO from '../components/seo';
import "../css/typography.css";
import portrait from "../images/portrait.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Seraph Kumar" keywords={['Seraph', 'kumar', 'designer', 'user', 'experience', 'product', 'interaction', 'gatsby', 'application', 'react']} mdxType="SEO" />
    <br /> <br /> 
    <img height="600px" loading="eager" src={portrait} alt="A portrait of me." className="center" />
    <div style={{
      fontWeight: '400',
      marginTop: '60px'
    }}>
  Seraph Kumar is a result-driven Senior Product Designer experienced in Enterprise, B2B, and SaaS. Committed to creating outstanding user experiences using research-based design, teamwork, and innovation.
  Has been designing from California for over <Experience mdxType="Experience" /> years.<br /> 
  <Link to="/about/" mdxType="Link">Learn more about Seraph</Link>
  <div className="indexParent">
    <div className="indexChild">
      <h6> Work </h6>
      <div style={{
            lineHeight: '2'
          }}>
        <Link to="/billgo/" mdxType="Link">Fintech platform</Link>  <br />
        <Link to="/archipelago/" mdxType="Link">Risk Insurance platform</Link>  <br />
        <Link to="/hawthorne/" mdxType="Link">Ad data viz paltform</Link>  <br />
        <Link to="/lab/" mdxType="Link">Genomics software & hardware</Link>  <br />
        <Link to="/doux/" mdxType="Link">Other</Link>
      </div>
    </div>
    
    <div className="indexChild">
      <h6> Links </h6>
      <div style={{
            lineHeight: '2'
          }}>
        <a href="mailto:hello@seraphkumar.com">Email hello@seraphkumar.com</a>  <br />
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/seraphkumar/">Go to LinkedIn</a>  <br />
      </div>
    </div>
  </div>
  <div>
    <h6> Blog </h6>
    <div style={{
          lineHeight: '2'
        }}>
      <Link to="/blog4/" mdxType="Link">Quick Guide to Data Visualizations</Link>  <br />
      <Link to="/blog3/" mdxType="Link">The (Pixel) Art of Animal Crossing Flags</Link>  <br />
      <Link to="/resources/" mdxType="Link">Resources</Link>  <br />
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      